<template>
  <div class="container position-relative">
    <div style="width: 0; height: 0; position: absolute; top: -75px" id="explor-pharmacy"></div>

    <div class="map-container" style="width: 100%;">
      <div class="map-sub-container" style="max-width: 500px; margin: 40px auto 0;">
        <div class="map-image col-sm-12">
          <div class="d-inline-block map-section-container west-container" style="" @click="toggleRegion('west')">
            <span :class="'map map-west ' + checkSelected('west')" style=""></span>
          </div>
          <div class="order-2 d-inline-block map-section-container midwest-container" style="" @click="toggleRegion('midwest')">
            <span :class="'map map-midwest ' + checkSelected('midwest')" style=""></span>
          </div>
          <div class="d-inline-block map-section-container northeast-container"  style="" @click="toggleRegion('northeast')">
            <span :class="'map map-northeast ' + checkSelected('northeast')" style=""></span>
          </div>
          <div class="d-inline-block map-section-container southwest-container" style="" @click="toggleRegion('southwest')">
            <span :class="'map map-southwest ' + checkSelected('southwest')" style=""></span>
          </div>
          <div class="order-0 d-inline-block map-section-container southeast-container" style="" @click="toggleRegion('southeast')">
            <span :class="'map map-southeast ' + checkSelected('southeast')" style=""></span>
          </div>
        </div>
      </div>

      <div id="school-list-container" class="map-school-list row" style="margin-top: 15px; min-height: 400px;"
           v-masonry="selected-map-container" transition-duration="0.3s" item-selector=".visible-masonry-item">
        <div id="selected-map-container" v-for="(location, key) in active_school_information" :key="key"
             :class="(selected_map_locations[key] === true || map_has_region_selected === false) ? 'col-md-6 visible-masonry-item' : 'd-none'">
          <div class="selected-map-container" v-if="selected_map_locations[key] === true || map_has_region_selected === false">
            <h2 class="h2 subtitle text-center d-inline-block position-relative text-capitalize" style="width: 100%">{{ key }}</h2>
            <div class="region-container">
              <div class="row" v-for="(school, school_key) in school_information[key]" :key="school_key" style="margin-bottom: 15px;">
                <div class="col-2 d-flex align-items-center text-center justify-content-center">
                  <a  :href="school.link" target="_blank">
                    <img :src="require(`../assets/images/logos/` + school.logo)" :alt="school.name" class="img-fluid" style="max-height: 48px;" />
                  </a>
                </div>
                <div class="col-md-7 col-6">
                  <div>
                    <h6 class="h6 subtitle d-inline-block position-relative text-capitalize" style="margin: 0">{{ school.name }}</h6>
                    <span class="small d-block">
                      {{ school.city }}, {{ school.state }}
                    </span>
                  </div>
                </div>
                <div class="col-md-3 col-4 d-flex align-items-center justify-content-end">
                  <a :href="school.link" target="_blank" class="btn btn-primary" style="padding: 8px 25px 8px 25px;">explor</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MapWidget',
  props: {
  },
  data() {
    return {
      map_has_region_selected: false,
      selected_map_locations: {
        'midwest': false,
        'northeast': false,
        'southeast': false,
        'southwest': false,
        'west': false
      },
      active_school_information: {},
      school_information: {
        midwest: [
          {
            name: 'Manchester University Pharmacy Exploration Center',
            city: 'Fort Wayne',
            state: 'IN',
            link: 'https://bit.ly/explormanchester',
            logo: 'manchester-university-logo.png'
          },
          {
            name: 'University of Minnesota Pharmacy Exploration Center',
            city: 'Minneapolis',
            state: 'MN',
            link: 'https://bit.ly/explorUMN',
            logo: 'umn-college-of-pharmacy-logo.png'
          }
        ],
        northeast: [
          {
            name: 'University at Buffalo Pharmacy Exploration Center',
            city: 'Buffalo',
            state: 'NY',
            link: 'https://bit.ly/explorUB',
            logo: 'university-at-buffalo-logo.png'
          },
          {
            name: 'University of Rhode Island PharmD Exploration Center',
            city: 'Kingston',
            state: 'RI',
            link: 'https://bit.ly/explorURI',
            logo: 'uri-logo.png'
          },
        ],
        southeast: [
          {
            name: 'Florida A&M University Pharmacy Exploration Center',
            city: 'Tallahassee',
            state: 'FL',
            link: 'https://bit.ly/explorFAMU',
            logo: 'famu-logo.png'
          },
          {
            name: 'Medical University of South Carolina Pharmacy Exploration Center',
            city: 'Charleston',
            state: 'SC',
            link: 'https://bit.ly/explorMUSC',
            logo: 'musc-logo.png'
          },
          {
            name: 'Xavier University of Louisiana College of Pharmacy',
            city: 'New Orleans',
            state: 'LA',
            link: 'https://bit.ly/explorXavier',
            logo: 'xavier-university-logo.png'
          },
          {
            name: 'Lipscomb University Pharmacy Exploration Center',
            city: 'Nashville',
            state: 'TN',
            link: 'https://bit.ly/explorLipscomb',
            logo: 'lipscomb-logo.png'
          },
          {
            name: 'University of South Carolina Pharmacy Exploration Center',
            city: 'Columbia',
            state: 'SC',
            link: 'https://corereadiness.com/learning-center/i/P11433598',
            logo: 'USC_Pharmacy_logo_horizontal_CMYK_2C.jpg'
          },
          {
            name: 'Hampton University School of Pharmacy',
            city: 'Hampton',
            state: 'VA',
            link: 'https://corereadiness.com/learning-center/i/P17201910',
            logo: 'hampton-logo.jpg'
          }
        ],
        southwest: [
          // {
          //   name: 'TTUHSC Jerry. H. Hodge Pharmacy Exploration Center',
          //   city: 'Lubbock',
          //   state: 'TX',
          //   link: 'https://bit.ly/explorTTUHSC',
          //   logo: 'texas-tech-logo.png'
          // },
          {
            name: 'University of Texas at Tyler Pharmacy Exploration Center',
            city: 'Tyler',
            state: 'TX',
            link: 'https://bit.ly/explorUTTyler',
            logo: 'university-texas-tyler-logo.png'
          }
        ],
        west: [
          {
            name: 'University of Hawaii at Hilo DKICP Pharmacy Exploration Center',
            city: 'Hilo',
            state: 'HI',
            link: 'https://bit.ly/explorDKICP',
            logo: 'university-of-hawaii-logo.png'
          },
          {
            name: 'Idaho State University Pharmacy Exploration Center',
            city: 'Pocatello',
            state: 'ID',
            link: 'https://bit.ly/explorISU',
            logo: 'orange-spirit-mark.png'
          }
        ]
      }
    }
  },
  created() {
    this.active_school_information = this.school_information;
  },
  methods: {
    toggleRegion(location) {
      this.selected_map_locations[location] = !this.selected_map_locations[location]
      this.map_has_region_selected = this.mapHasRegionSelected();

      this.active_school_information = {};
      for (let school in this.school_information) {
        if (this.selected_map_locations[school] === true || this.map_has_region_selected === false) {
          this.active_school_information[school] = this.selected_map_locations[school];
        }
      }
      this.redrawMasonry()
    },
    async redrawMasonry() {
      await new Promise(resolve => setTimeout(resolve, 100));
      this.$redrawVueMasonry();
    },
    mapHasRegionSelected() {
      for (let selectedMapLocationsKey in this.selected_map_locations) {
        if (this.selected_map_locations[selectedMapLocationsKey] === true) {
          return true;
        }
      }
      return false;
    },
    checkSelected(index) {
      if (this.selected_map_locations[index] === true) {
        return 'map-section-selected';
      }
      return '';
    },
  }
}
</script>

<style scoped>
.region-container {
  /*background: #fff;*/
  padding: 25px 10px 10px;
  border-radius: 7px;
  /*box-shadow: 0 8px 13px rgb(0 0 0 / 15%);*/
}
.region-container .btn {
  box-shadow: none;
}
.map-school-list {
  list-style: none;
}
.map-school-list ul li {
  display: inline-block;
}
.map-image {
  position: relative;
  height: 250px;
}
.selected-map-container {
  margin-top: 20px;
}
.map {
  display: inline-block;
  width: 70px;
  height: 70px;
  background: #769cbd;
  mask-size: cover;
}
.map-section-container {
  position: absolute;
}
.map-section-selected {
  background: #2f516f;
}
.map-west {
  mask: url(../assets/images/map/west.svg);
}
.map-northeast {
  mask: url(../assets/images/map/northeast.svg);
}
.map-midwest {
  mask: url(../assets/images/map/midwest.svg);
}
.map-southeast {
  mask: url(../assets/images/map/southeast.svg);
}
.map-southwest {
  mask: url(../assets/images/map/southwest.svg);
}
.west-container {
  top:-1px;
  left:-3px;
}
.west-container span {
  width: 190px;
  height: 268px;
}
.midwest-container {
  top: 12px; left: 185px; z-index: 10;
}
.midwest-container span {
  width: 160px;height: 120px;
}
.northeast-container {
  top: 5px; left: 348px;
}
.northeast-container span {
  width: 81px;height: 103px;
}
.southwest-container {
  top:122px; left:98px;
}
.southwest-container span {
  width: 153px;height: 107px;
}
.southeast-container {
  left:255px; top: 96px;
}
.southeast-container span {
  width: 140px;height: 140px;
}
.map-sub-container {
  padding-left: 20px;
}
@media (max-width: 991.98px) {
  .west-container {
    top:-1px;
    left:-3px;
  }
  .map-sub-container {
    padding-left: 0;
    transform: scale(.9);
  }
  .west-container span {
    width: 162px;
    height: 229px;
  }
  .midwest-container {
    top: 12px;
    left: 155px;
    z-index: 10;
  }
  .midwest-container span {
    width: 130px;
    height: 98px;
  }
  .northeast-container {
    top: -1px;
    left: 287px;
  }
  .northeast-container span {
    width: 73px;
    height: 93px;
  }
  .southwest-container {
    top:102px;
    left:85px;
  }
  .southwest-container span {
    width: 123px;
    height: 86px;
  }
  .southeast-container {
    left:212px;
    top: 83px;
  }
  .southeast-container span {
    width: 107px;height: 107px;
  }
}
</style>
